:root {
  --background-one: #fefaf4;
  --background-two: #263238;
  --delete: #fc4a04;
  --rust: #ff9800;
  --stout: #361002;
  --leaf: #b75a0e;
  --bark: #5f3c0f;
  --beerfoam: #f4db9d;
  --bluetorch: #21b1ff;
}

html { 
  overflow-y: scroll;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    background-color: var(--background-one);  
}

.sheet {
  background-image: url("./images/sheet-music.png");
  background-size: cover;
}

.container {
  margin: 9rem auto 0 auto;
  max-width: 1200px;
}

img {
  -webkit-touch-callout: none; 
  -webkit-user-select: none;
  user-select: none;
}

.nav-logo {
  width: 300px;
  height: 74px;
  margin-top: 3px;
  margin-right: auto;
  margin-left: auto;
}

 @media(max-width: 767px){
  .nav-logo {
    display: none;
  }
  .container {
    margin: 9rem auto 0 auto;
  }
  /* .desktop-howls {
    display: none;
    height: 0px;
    width: 0px;
  }
  .mobile-howls {
    display: block;
  } */
} 

@media(min-width: 768px){
  .desktop-howls {
    display: block;
  }
  .mobile-howls{
    display: none;
    height: 0px;
    width: 0px;
  }
}
.nav-container {
  margin: auto;
}

.MuiDialogActions-root, .MuiDialogTitle-root {
  justify-content: center !important;
  background-color: var(--background-two);
}
.MuiDialog-paper {
  border-radius: 16px !important;
}

.MuiToolbar-gutters {
  padding-left: 0 !important;
  padding-right: 0 !important; 
}

a {
  text-decoration: none;
  color: var(--background-two);
}

a:hover {
  color: var(--rust);
}

.bar-separator {
  margin: 6px 0 6px 0;
  height: 1px;
  background-color: var(--rust);
  border: none;
  width: 100%;
}

.bar-separator-oak {
  margin: 6px 0 6px 0;
  height: 2px;
  background-color: var(--background-two);
  border: none;
  width: 100%;
}

.bar-separator-booz {
  margin: 6px 0 6px 0;
  height: 1px;
  background-color: var(--rust);
  border: none;
  width: 100%;
}

.sparkTitle {
  color: var(--rust);
  text-align: center;
  font-size: 2rem;
}

.boozTitle1 {
  color: var(--rust);
  text-align: center;
  font-size: 2rem;
}

.okeTitle {
  color: var(--background-two);
  text-align: center;
  font-size: 2rem;
}

form {
  text-align: center;
  margin: 0 auto;
}

.boozTitle2 {
  color: var(--rust);
  font-size: 2rem;
}

.infernal-type {
  font-family: 'Freckle Face', cursive !important;
  color: var(--rust);
  text-align: center;
  font-size: 2.5rem;
  text-shadow: 
  3px 0 2px var(--background-two),
  -3px 0 2px var(--background-two),
  0 3px 2px var(--background-two),
  0 -3px 2px var(--background-two),
  0 0 24px var(--beerfoam);
}

em, strong, h2 {
  font-family: 'Baloo', cursive;
  font-size: 1.1em;
}
input {
  font-family: 'Palanquin Dark', sans-serif !important;
}

.icon {
  font-size: 3.5rem !important;
}

.icon2 {
  font-size: 2.5rem !important;
}
.icon4{
  font-size: 2rem !important; 
}
.icon5 {
  font-size: 4rem !important;
}
.icon6 {
  font-size: 1.5rem !important;
}
.icon7 {
  font-size: 7rem !important;
  color: var(--rust);
  align-self: center !important;
}
.icon8 {
  font-size: 2.5rem !important;
}
.icon8-2 {
  font-size: 2.5rem !important;
  color: var(--rust);
}
.icon8:hover, icon8-2:hover {
  color: var(--rust) !important;
}
.icon9 {
  font-size: 2.5rem !important;
  position: absolute;
  top: 16%;
  left: 84%;
}
.icon10 {
  font-size: 4rem !important;
  position: absolute;
  top: 8%;
  left: 82%;
}
.icon11 {
  font-size: 4rem !important;
  align-self: center !important;
}
.icon12 {
  font-size: 2.5rem !important;
  position: absolute;
  top: -56%;
}

.icon13 {
  font-size: 3rem !important;
  position: absolute;
}
.icon14 {
  font-size: 3rem !important;
  position: absolute;
  margin-left: 24px !important;
  margin-right: -16px !important;
}

.icon15 {
  font-size: 200% !important;
  margin-top: -20px;
  margin-bottom: -16px;
}

.icon16 {
    font-size: 3.5rem !important;
    margin-top: 6px;
}

.icon16-2 {
  font-size: 2.5rem !important;
  margin-top: 6px;
  float: right;
}

.icon4:hover{
  font-size: 2.5rem !important;
  color: var(--delete);
}

.icon3:hover {
  font-size: 2rem;
  color:  var(--delete);
}
.nav-container svg {
  color: var(--beerfoam);
  font-size: 200%;
}
.nav-container svg:hover {
  color: var(--rust);
}
.candle {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.footer {
  background-color: var(--background-two);
  width: auto;
  max-width: 1600px;
  text-align: left;
  color:var(--rust);
  padding: 16px 16px 0 16px;
  margin: 8px auto 0 auto;
  border-radius: 16px 0 16px 0;
  position: relative;
  display: flex;
  flex-direction: row;
}

.orange:hover {
  color: var(--rust);
}

.foam {
  color: var(--beerfoam);
}

.rust:hover {
  color: var(--rust);
}

.charcoal-border {
  border: 16px solid var(--background-two);
}

.rust-border{
  border: 16px solid var(--rust);
} 

.orange-border {
  border: 16px solid var(--rust);
}

.oke-border {
  border: 16px solid var(--beerfoam);
}

.rusty {
  color: var(--rust);
  text-align: center;
}

.rusty2 {
  color: var(--rust) !important;
}

.orng {
  color: var(--rust);
} 

.not-orng:hover {
  color: var(--background-two);
  background-color: var(--rust);
}

.oakleaf:hover {
  color: var(--background-two);
}

.oaky {
  color: var(--background-two);
}

.warning-label {
  color: var(--delete);
  font-size: .75rem;
}

@-moz-document url-prefix() {
  audio {
    background-color: var(--rust) !important;
    border-radius: 16px 0 16px 0;
  }
  .controlBar {
    background-color: none !important;
  }
}


audio::-webkit-media-controls-panel {
  background-color: var(--rust);
}

audio::-webkit-media-controls-enclosure {
  border-radius: 16px 0 16px 0;
}
#aPlayer, .vid-cont {
  border-radius: 16px 0 16px 0;
}

.dark {
  background-color: var(--background-two);
  width: 960px;
}

.foam {
  color: var(--beerfoam);
}

.torch {
  color: var(--bluetorch);
}

.redhot {
  color: var(--delete);
}

.infernal {
  color: var(--background-two);
}

.viewer {
  padding: 32px !important;
}

.breaks {
    white-space: pre-wrap;
}

.load {
  font-size: 3rem;
}

.MuiInputLabel-formControl {
  left: 16px !important;
}

.reset-form-62 MuiGrid-container {
  display: block !important;
}

.center {
  margin: 0 auto;
  text-align: center;
}

.menu {
  max-width: 100vw;
  font-size: .25rem;
}

body > div:nth-child(7) > div.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation8.MuiPopover-paper.MuiPaper-rounded > ul {
  background-color: var(--background-two);
  color: var(--rust);
  text-align: center;
}

.smaller {
  font-size: 10px;
}

.fireGrid {
  background-color: var(--background-two);
}


/* toggle styling */
.toggle, .toggler {
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
}

.toggler {
  color: #ddd;
  transition: .2s;
  font-weight: bold;
}

.toggler--is-active {
  color: #ff9800;
  font-size:22px;
}

.b {
  display: block;
}

.toggle {
  position: relative;
  width: 80px;
  height: 35px;
  border-radius: 100px;
  background-color: #f4db9d;
  overflow: hidden;
  box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.05);
}

.check {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 6;
}

.check:checked ~ .switch {
  right: 2px;
  left: 57.5%;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: .08s, 0s;
}

.switch {
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  right: 57.5%;
  background-color: #fff;
  border-radius: 36px;
  z-index: 1;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0s, .08s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}


.hide{
  display: none;
}

.flipper {
    margin: 0 auto;
}

.rank-section {
    padding:  0;
    float: left;
    width: 100%;
    text-align: center;
}


.blue-toggle {
    background-color: #263238 !important;
}

.toggle-text {
  font-family: 'Baloo';
}

.centered {
  margin: 0 auto;
  text-align: center;
}

#root > div.container > div > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-4 > div.MuiPaper-root.MuiPaper-elevation1.StaticProfile-paper-263.MuiPaper-rounded > div > button > span.MuiIconButton-label {
  align-self: center !important;
  text-align: center !important;
  margin: 0 auto !important;
  justify-content: center;
}

.silentToggle {
  position: absolute;
  left: 78%;
  top:26%;
  float: right !important;
  justify-content: flex-end;
  margin-bottom: 16px !important;
}

.footer-text {
  font-size: 12px;
  width: 460px !important;
  margin-top: 16px;
}

.bar-separator-footer {
  margin: 16px;
  height: 1px;
  background-color: var(--rust);
  border: none;
  width: 100%;
}


/* Clock stuff */
.most-inner {
  text-align: center;
  height:44px;
  width: 100px;
  margin-top: 16px;
  border: 1px solid var(--rust);
  border-radius: 16px 0 16px 0;
  box-shadow: 
    0px 0px 4px 2px var(--rust), 
    inset 0px 0px 2px 2px var(--background-two);
  background-color: var(--background-one);
  text-shadow: 0px 0px 2px var(--rust);
}

.time {
  color: var(--rust);
  font-size: 18px;
  position: relative;
  display: block;
  margin-top: 6px;
  /* margin-left: -30px */
}

.amPm {
  position: relative;
  display: block;
  width: 20px;
  margin: 0 auto 8px auto;
  color: var(--rust);
  font-size: 12px;
  text-align: center;
}

@keyframes blinking {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes shadowFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

.blink {
  animation: blinking .5s infinite
}



body > div > div.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation8.MuiPopover-paper.MuiPaper-rounded  {
  width: 480px !important;
  margin-bottom: 160px !important;
}

#root > div.container > div > div:nth-child(2) > div.candle.centered > button > span.MuiIconButton-label svg {
  color: var(--background-two) !important; 
  font-size: 3rem !important;
}

#root > div.container > div > div:nth-child(2) > div.candle.centered > button > span.MuiIconButton-label > svg:hover {
  color: var(--rust) !important;
}

.post-text {
  text-align: center !important;
}

#root > div.container > div > div:nth-child(2) > div.candle.centered > button:hover {
  background-color: transparent !important;
}

#root > header > div.MuiToolbar-root.MuiToolbar-regular.nav-container.MuiToolbar-gutters > button:nth-child(1) > span.MuiIconButton-label > svg {
  font-size: 200% !important;
}

#root > div.container > div > div > div > span > h3 {
  margin-left: 16px;
}

.howlIcon {
  margin-left: 2rem;
  margin-top: 1rem;
  float: left;
}

@media(max-width: 959px){
  #root > div.container > div > div.MuiGrid-root.howls-listBottom-134.MuiGrid-item.MuiGrid-grid-md-3 {
    display: none !important;
  }
}

.list-bottom{
  border-bottom: 3px solid #ff9800;
  background-color: #263238;
  border-left: 3px solid #ff9800;
}

@media(max-width: 959px) {
  .list-bottom{
    display: none !important;
    visibility: hidden;
    background-color: #fefaf4 !important;
    border: none;
    }
}

.needs-padding {
  padding-bottom: 16px;
  margin-top: 8px;
}

#aPlayer > audio { width: 80%; margin-top: 16px; }
/* Chrome 29+ */


audio::-internal-media-controls-overflow-button {
  display: none !important;
  appearance: none !important;
  visibility: hidden !important;
}